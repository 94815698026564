import {
  isToday,
  isYesterday,
  format,
  differenceInHours,
  formatDistanceToNowStrict,
} from 'date-fns';

const differenceInHoursToNow = (date: number) => {
  return differenceInHours(Date.now(), date);
};

const formatTodayTime = (timestamp: number) => {
  if (differenceInHoursToNow(timestamp) > 12) {
    return format(timestamp, 'h:mm aaa');
  }

  return `${formatDistanceToNowStrict(timestamp)} ago`;
};

const formatUploadTime = (date: string) => {
  const uploadDateTimestamp = new Date(date).getTime();

  if (isToday(uploadDateTimestamp)) {
    return formatTodayTime(uploadDateTimestamp);
  }

  if (isYesterday(uploadDateTimestamp)) {
    return 'Yesterday';
  }

  return format(uploadDateTimestamp, 'MM/dd/yyyy');
};

const useFormattedCommentTime = (dateTime: string) => {
  return formatUploadTime(dateTime);
};

export default useFormattedCommentTime;
