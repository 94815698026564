import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: relative;
  flex-shrink: 0;

  @media (min-width: 768px) {
    width: 22.5rem;
  }
`;

const FixedOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #FFF;

  @media (min-width: 768px) {
    width: 22.5rem;
    position: fixed;
    top: 4rem;
    bottom: 0rem;
    overflow: auto;

    padding-bottom: 1rem;
    box-shadow: 0px 4px 8px rgba(64, 48, 141, 0.08);
  }

  @media (min-width: 992px) {
    top: 0rem;
  }
`;

const DetailSection: React.FC = ({ children }) => {
  return (
    <Container>
      <FixedOverlay>
        {children}
      </FixedOverlay>
    </Container>
  );
};

export default DetailSection;
