import React from 'react';
import styled from 'styled-components/macro';
import { CommentInput, PostCommentButton } from '../../components';
import { useMobileAppPopup } from '../../hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;

  padding-top: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  border-bottom: 1px solid #EFEFEF;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ReactionDetailsCommentBox: React.FC = () => {
  const { openPopup } = useMobileAppPopup();

  return (
    <Container onClick={openPopup}>
      <CommentInput
        rows={3}
        placeholder="Add comment"
        disabled
      />
      <Footer>
        <PostCommentButton disabled>
          Post
        </PostCommentButton>
      </Footer>
    </Container>
  );
};

export default ReactionDetailsCommentBox;
