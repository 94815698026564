import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import useQueryStringParser from './use-query-string-parser';

type InputParams = {
  [key: string]: unknown;
};

const useQueryParams = () => {
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = useQueryStringParser(search);

  const onChangeQueryParams = (newParams: InputParams) => {
    const stringifiedParams = queryString.stringify(newParams, {
      arrayFormat: 'none',
    });

    history.push({
      search: `?${stringifiedParams}`,
    });
  };

  return [queryParams, onChangeQueryParams] as const;
};

export default useQueryParams;
