import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Nullable } from '../utils/type-utils';

type PaginationPage<PageItem> = Nullable<PageItem[]>;
type FetchedPage<PageItem> = {
  pageNumber: number;
  page: PaginationPage<PageItem>;
};

const usePagination = <PageItem>() => {
  const [pages, setPages] = useState<PaginationPage<PageItem>[]>([]);
  const [fetchedPage, setFetchedPage] = useState<Nullable<FetchedPage<PageItem>>>(null);

  useEffect(() => {
    const page = fetchedPage?.page || [];
    const pageNumber = fetchedPage?.pageNumber;
    const pageIndex = pageNumber ? pageNumber - 1 : null;

    if (!_.isNil(pageIndex)) {
      const newPages = [...pages];
      newPages[pageIndex] = [...page];
      setPages(newPages);
    }
  }, [fetchedPage]);

  const setPage = (pageParams: FetchedPage<PageItem>) => {
    setFetchedPage(pageParams);
  };

  const resetPages = () => {
    setPages([]);
  };

  return [pages, setPage, resetPages] as const;
};

export default usePagination;
