import React from 'react';
import styled from 'styled-components/macro';
import StylelessButton from '../styleless-button';
import Icons from '../icons';
import StylelessAnchor from '../styleless-anchor';
import Config from '../../config';

const Container = styled.div`
  height: 3rem;
  background-color: #795DE7;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0rem 1rem;
`;

const Message = styled(StylelessAnchor)`
  color: #FFF !important;
  font-size: 1rem;
  font-weight: 800;
  margin-right: 1rem;
`;

const CloseButton = styled(StylelessButton)`
`;

const IosBanner: React.FC<{
  onClose: () => void;
  className?: string;
}> = ({ onClose, className }) => {
  return (
    <Container className={className}>
      <Message
        href={Config.MobileApp.iosLink}
      >
        Click here to download the iOS app!
      </Message>
      <CloseButton
        onClick={onClose}
      >
        <Icons.Close />
      </CloseButton>
    </Container>
  );
};

export default IosBanner;
