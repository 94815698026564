import React from 'react';
import { useHistory, generatePath } from 'react-router';
import ReactResizeDetector from 'react-resize-detector';
import { ProductionCard } from '../../components';
import { usePosterHeight } from '../../hooks';
import { ProductionModel } from '../../services/production-service';
import { Paths } from '../../routes';

const ProductionDetailsProductionCard: React.FC<{
  production: ProductionModel;
}> = ({ production }) => {
  const [posterHeight, onWidthChange] = usePosterHeight();
  const history = useHistory();

  return (
    <ReactResizeDetector
      handleWidth
      onResize={onWidthChange}
    >
      <ProductionCard.Container>
        <ProductionCard.Media
          $height={posterHeight}
        >
          <ProductionCard.MediaVideo
            productionId={production.id}
            $height={posterHeight}
          >
            <ProductionCard.MediaPoster
              src={production.poster}
              title={production.title}
              $height={posterHeight}
            />
          </ProductionCard.MediaVideo>
        </ProductionCard.Media>
        <ProductionCard.Score
          score={production.score}
        />
        <ProductionCard.BodyWrapper>
          <ProductionCard.Categorization
            type={production.type}
            genres={production.genres}
          />
          <ProductionCard.Title
            productionId={production.id}
            title={production.title}
          />
          <ProductionCard.Reactions
            reactionsCount={production.reactionsCount}
          />
          <ProductionCard.Footer
            totalRatings={production.totalRatings}
          >
            <ProductionCard.Button
              onClick={() => {
                history.push(generatePath(Paths.ProductionDiscussion, {
                  id: production.id,
                }));
              }}
            >
              Discussion
            </ProductionCard.Button>
          </ProductionCard.Footer>
        </ProductionCard.BodyWrapper>
      </ProductionCard.Container>
    </ReactResizeDetector>
  );
};

export default ProductionDetailsProductionCard;
