import React from 'react';
import styled from 'styled-components/macro';
import StylelessButton from '../styleless-button';
import Icons from '../icons';
import { useMobileAppPopup } from '../../hooks';

const Container = styled.div`
  width: 30%;
  padding: 0rem 0.75rem;
`;

const Label = styled.div`
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
`;

const ProductionCardWatchlist: React.FC = () => {
  const { openPopup } = useMobileAppPopup();

  return (
    <Container>
      <StylelessButton onClick={openPopup}>
        <Icons.Watch />
        <Label>
          Add
        </Label>
      </StylelessButton>
    </Container>
  );
};

export default ProductionCardWatchlist;
