import styled from 'styled-components/macro';

const ReactionCardHeader = styled.div`
  display: flex;
  align-items: center;

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

export default ReactionCardHeader;
