import styled from 'styled-components/macro';
import { Popover, PopoverProps } from '@material-ui/core';

const ContentSelectorPopover = styled(Popover).attrs((): Partial<PopoverProps> => ({
  classes: {
    root: 'popoverRoot',
    paper: 'popoverPaper',
  },
}))`
  &.popoverRoot {
    background-color: rgba(0,0,0,0.15);
  }

  & .popoverPaper {
    width: 23.5rem;
    background-color: transparent !important;
    box-shadow: none !important;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-x: unset !important;
    overflow-y: unset !important;
  }
`;

export default ContentSelectorPopover;
