import _ from 'lodash';

export const chunk = <T>(arr: T[], chunkSize: number) => {
  const chunked: T[][] = [];
  for (let start = 0; start < arr.length; start += chunkSize) {
    chunked.push(arr.slice(start, start + chunkSize));
  }

  return chunked;
};

export const segmentIndexMapper = <T, U>(
  arr: T[],
  segmentLength: number,
  targetIndex: number,
  callback: (element: T, index: number) => U,
) => {
  if (segmentLength < 1) return;

  const mappedArr: U[] = [];

  for (
    let index = targetIndex;
    index < arr.length;
    index += segmentLength
  ) {
    mappedArr.push(callback(arr[index], index));
  }

  return mappedArr;
};

export const mapN = <T>(
  count: number,
  mapper: (index: number) => T,
) => {
  const arr: T[] = [];
  for (let index = 0; index < count; index += 1) {
    arr.push(mapper(index));
  }

  return arr;
};

export const findByIndex = <T>(arr: T[], index: number): T | undefined => {
  return arr[index];
};

export const getFirstIfArrayOrSelf = <T>(arr: T | T[]): T | undefined => {
  return _.isArray(arr) ? arr[0] : arr;
};

export const wrapInArrayIfSingle = <T>(element: T | T[]) => {
  return _.isArray(element) ? element : [element];
};
