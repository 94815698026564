import { PublicApi } from './apis';
import { ApiResponse } from './apis/apis';
import { Nullable, Nilable } from '../utils/type-utils';

export type ReactionType = Nilable<'video' | 'image' | 'text' | 'giphy'>;
export const REACTION_TYPES: { [key: string]: ReactionType } = {
  VIDEO: 'video',
  IMAGE: 'image',
  TEXT: 'text',
  GIPHY: 'giphy',
};

export type ReactionModel = {
  id: number;
  video: string;
  reactorName: string;
  reactorPicture: string;
  productionName: string;
  uploadDate: string;
  likes: number;
  liked: boolean;
  comments: number;
  caption?: string | null;
  episode?: number | null;
  season?: number | null;
  thumbnail: string;
  type: ReactionType;
  format: Nilable<string>;
  giphyId: Nilable<string>;
};

type ApiReactionModel = {
  id: number;
  created_at: string;
  updated_at: string;
  caption: string;
  productionName: string;
  userDisplayName: Nullable<string>;
  userPhotoUrl: Nullable<string>;
  likes: number;
  rating: 0 | 1;
  comment_count: number;
  season: Nullable<number>;
  episode: Nullable<number>;
  video: string;
  thumbnail: Nilable<string>;
  type: ReactionType;
  format: Nilable<string>;
  giphyId: Nilable<string>;
};

const mapApiModel = (model: ApiReactionModel): ReactionModel => {
  return {
    id: model.id,
    video: model.video,
    reactorName: model.userDisplayName || '',
    reactorPicture: model.userPhotoUrl || '',
    productionName: model.productionName,
    uploadDate: model.created_at,
    likes: model.likes,
    liked: !!model.rating,
    comments: model.comment_count,
    caption: model.caption,
    episode: model.episode,
    season: model.season,
    thumbnail: model.thumbnail || '',
    type: model.type,
    format: model.format,
    giphyId: model.giphyId,
  };
};

class ReactionService {
  public static getTop = async (params: {
    limit: number;
    page: number;
  }) => {
    try {
      const response: ApiResponse<ApiReactionModel[] | null> = await PublicApi.getTopReactions({
        request: {
          limit: params.limit,
          page: params.page,
        },
      });
      const results = response?.data || null;

      const reactions: Nullable<ReactionModel[]> = results?.map(mapApiModel) || null;

      return reactions;
    } catch (err) {
      return null;
    }
  };

  public static getByProduction = async (params: {
    productionId: number;
    sort: string;
    limit: number;
    page: number;
    season?: Nullable<number>;
    episode?: Nullable<number>;
  }) => {
    try {
      const response: ApiResponse<
      Nullable<ApiReactionModel[]>
      > = await PublicApi.getReactionsByProduction({
        path: {
          id: params.productionId,
        },
        request: {
          limit: params.limit,
          page: params.page,
          sort: params.sort,
          season: params.season || undefined,
          episode: params.episode || undefined,
        },
      });
      const results = response?.data || null;

      const reactions: Nullable<ReactionModel[]> = results?.map(mapApiModel) || null;

      return reactions;
    } catch (err) {
      return null;
    }
  };

  public static getById = async (reactionId: number) => {
    try {
      const response: ApiResponse<
      Nullable<ApiReactionModel>
      > = await PublicApi.getReactionById({
        path: {
          id: reactionId,
        },
      });
      const result = response?.data || null;

      const reaction: Nullable<ReactionModel> = result ? mapApiModel(result) : null;

      return reaction;
    } catch (err) {
      return null;
    }
  };
}

export default ReactionService;
