import { useParams } from 'react-router';

const useReactionIdFromPath = () => {
  const { id: reactionIdParam } = useParams();

  const reactionId = Number(reactionIdParam);

  return reactionId;
};

export default useReactionIdFromPath;
