import React from 'react';
import styled from 'styled-components/macro';
import Icons from '../icons';
import { Paths } from '../../routes';
import ContentSelectorOption from './content-selector-option';
import ContentSelectorPopover from './content-selector-popover';

const Arrow = styled.div`
  margin-top: 0.5rem;
  border-style: solid;
  border-color: #FFF;
  border-width: 10px 12px;

  border-top-width: 0px;
  border-left-color: transparent;
  border-right-color: transparent;

  width: 0;
`;

const Body = styled.div`
  width: 100%;
  background-color: #FFF;
  box-shadow: 0px 16px 16px rgba(64, 48, 141, 0.16);
  border-radius: 16px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 1.3125rem;
  line-height: 2rem;

  padding: 0.75rem 1rem;
  border-bottom: 1px solid #EFEFEF;
`;

const Options = styled.div`
  padding: 0rem 1rem;
`;

const OptionIcon = styled.div`
  margin-right: 0.75rem;
  padding: 1rem 0rem;
`;

const OptionBody = styled.div`
  flex-grow: 1;
  padding: 1rem 0rem;
`;

const OptionBodyBordered = styled(OptionBody)`
  border-bottom: 1px solid #EFEFEF;
`;

const OptionTitle = styled.div`
  font-weight: 800;
`;

const OptionDescription = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #000;
`;

const ContentSelector: React.FC<{
  anchorEl: Element | null;
  onClose: () => void;
  selectedOption?: 'whats-poppin' | 'top-reactions';
}> = ({
  anchorEl,
  onClose,
  selectedOption,
}) => {
  return (
    <ContentSelectorPopover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Arrow />
      <Body>
        <Title>
          What are you seeing?
        </Title>
        <Options>
          <ContentSelectorOption selected={selectedOption === 'whats-poppin'} path={Paths.WhatsPoppin}>
            <OptionIcon>
              {selectedOption === 'whats-poppin' ? (
                <Icons.SelectedWhatsPoppin />
              ) : (
                <Icons.WhatsPoppin />
              )}
            </OptionIcon>
            <OptionBodyBordered>
              <OptionTitle>
                What's Poppin'
              </OptionTitle>
              <OptionDescription>
                Explore the best and latest content curated by us.
              </OptionDescription>
            </OptionBodyBordered>
          </ContentSelectorOption>
          <ContentSelectorOption selected={selectedOption === 'top-reactions'} path={Paths.TopReactions}>
            <OptionIcon>
              {selectedOption === 'top-reactions' ? (
                <Icons.SelectedTopReactions />
              ) : (
                <Icons.TopReactions />
              )}
            </OptionIcon>
            <OptionBody>
              <OptionTitle>
                Top Reactions
              </OptionTitle>
              <OptionDescription>
                Enjoy the top reactions from our community!
              </OptionDescription>
            </OptionBody>
          </ContentSelectorOption>
        </Options>
      </Body>
    </ContentSelectorPopover>
  );
};

export default ContentSelector;
