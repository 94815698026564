import React from 'react';
import styled from 'styled-components/macro';
import { ArrayUtils } from '../../utils';

const Container = styled.div`
  display: flex;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 2rem;
  }
`;

const Column = styled.div`
  width: 100%;
`;

const Row = styled.div`
  margin-bottom: 2rem;
`;

const ReactionsGridContainer: React.FC<{
  columns: number;
}> = ({
  children,
  columns,
}) => {
  const reactions = ArrayUtils.wrapInArrayIfSingle(children);
  return (
    <Container>
      {ArrayUtils.mapN(columns, (index) => (
        <Column key={`reactions-grid_column-${index}`}>
          {ArrayUtils.segmentIndexMapper(reactions, columns, index, (reaction, reactionIndex) => (
            <Row key={`reactions-grid_reaction-index-${reactionIndex}`}>
              {reaction}
            </Row>
          ))}
        </Column>
      ))}
    </Container>
  );
};

export default ReactionsGridContainer;
