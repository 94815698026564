import styled from 'styled-components/macro';

const ReactionCardFlatContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #FFF;

  & > * {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-shrink: 0;
  }
`;

export default ReactionCardFlatContainer;
