import React, { useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components/macro';
import { ProductionCard, Spinner } from '../../components';
import { Nullable } from '../../utils/type-utils';
import { ProductionModel } from '../../services/production-service';

const Container = styled.div`
  padding-bottom: 0.5rem;
  background-color: #FFFFFF;

  @media (min-width: 992px) {
    box-shadow: 0px 16px 16px rgba(64, 48, 141, 0.16);

    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 330px;
  }
`;

const LoadingProduction = styled(Spinner)`
  padding-top: 1rem;
  padding-bottom: 1.5rem;
`;

const ProductionDiscussionProductionSection: React.FC<{
  production: Nullable<ProductionModel>;
  className?: string;
}> = ({
  production,
  className,
}) => {
  const [width, setWidth] = useState(300);
  const posterHeight = width;

  return (
    <Container className={className}>
      <ReactResizeDetector
        handleWidth
        onResize={setWidth}
      >
        {production ? (
          <ProductionCard.Container>
            <ProductionCard.Media
              $height={posterHeight}
            >
              <ProductionCard.MediaPoster
                src={production.poster}
                title={production.title}
                $height={posterHeight}
              />
            </ProductionCard.Media>
            <ProductionCard.Score
              score={production.score}
            />
            <ProductionCard.BodyWrapper>
              <ProductionCard.Categorization
                type={production.type}
                genres={production.genres}
              />
              <ProductionCard.Title
                productionId={production.id}
                title={production.title}
              />
            </ProductionCard.BodyWrapper>
          </ProductionCard.Container>
        ) : (
          <LoadingProduction />
        )}
      </ReactResizeDetector>
    </Container>
  );
};

export default ProductionDiscussionProductionSection;
