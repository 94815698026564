import React from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components/macro';

const Container = styled.div`
  width: 100%;
  height: 100%;

  & .videoPlayerContainer {
    width: 100%;
    height: 100%;
    background-color: #000;

    padding: 1.5rem 0rem;
  }

  & .videoPlayer {
    width: 100%;
    height: 100%;

    /*
      TODO: On very specific widths, 
      Youtube iFrame spills 1px to the right.
      Not sure why.
    */
    padding-right: 1px;
  }
`;

const ProductionCardMediaVideoPlayer: React.FC<{
  youtubeVideoId: string;
  className?: string;
}> = ({ youtubeVideoId, className }) => {
  return (
    <Container className={className}>
      <YouTube
        containerClassName="videoPlayerContainer"
        className="videoPlayer"
        videoId={youtubeVideoId}
        opts={{
          playerVars: {
            autoplay: 1,
            controls: 0,
            modestbranding: 1,
            rel: 0,
            playsinline: 1,
          },
        }}
      />
    </Container>
  );
};

export default ProductionCardMediaVideoPlayer;
