import React from 'react';
import {
  useLocation,
  matchPath,
} from 'react-router';
import styled, { css } from 'styled-components/macro';
import { Icons, StylelessLink } from '../../components';
import { Paths } from '../../routes';
import ClientAppMenuItem from './client-app-menu-item';
import { menuItems } from './client-app-menu-items';

const itemStyle = css`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding-left: 1rem;
  font-weight: 800;
`;

const Header = styled.div`
  ${itemStyle}
  height: 5rem;
`;

const MenuItem = styled(ClientAppMenuItem)`
  ${itemStyle}
  height: 4rem;
`;

const SelectedMenuItem = styled(MenuItem)`
  color: #795DE7;
`;

const MenuItemTitle = styled.span`
  margin-left: 1rem;
`;

const Logo = styled(Icons.PvLogo)`
  width: 2rem;
`;

const ClientAppMenu: React.FC<{
  onClickItem?: () => void;
}> = ({ onClickItem }) => {
  const location = useLocation();

  return (
    <>
      <StylelessLink to={Paths.ClientApp} onClick={onClickItem}>
        <Header>
          <Logo />
        </Header>
      </StylelessLink>
      {menuItems.map((menuItem) => {
        const redirectPath = menuItem.getRedirect(location.pathname);
        return (
          matchPath(location.pathname, menuItem.rootPath) ? (
            <SelectedMenuItem
              key={menuItem.title}
              path={redirectPath}
              redirect={menuItem.redirect}
              onClick={onClickItem}
            >
              {menuItem.renderIcon(true)}
              <MenuItemTitle>
                {menuItem.title}
              </MenuItemTitle>
            </SelectedMenuItem>
          ) : (
            <MenuItem
              key={menuItem.title}
              path={redirectPath}
              redirect={menuItem.redirect}
              onClick={onClickItem}
            >
              {menuItem.renderIcon(false)}
              <MenuItemTitle>
                {menuItem.title}
              </MenuItemTitle>
            </MenuItem>
          ));
      })}
    </>
  );
};

export default ClientAppMenu;
