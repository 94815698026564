import React from 'react';
import styled from 'styled-components/macro';
import ProductionCardRate from './production-card-rate';
import ProductionCardWatchlist from './production-card-watchlist';
import { Nilable } from '../../utils/type-utils';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0.5rem;
`;

const ProductionCardFooter: React.FC<{
  totalRatings: Nilable<number>;
}> = ({ children, totalRatings }) => {
  return (
    <Container>
      <ProductionCardWatchlist />
      {children}
      <ProductionCardRate
        totalRatings={totalRatings}
      />
    </Container>
  );
};

export default ProductionCardFooter;
