import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import StylelessButton from '../styleless-button';
import { useProductionYoutubeVideo } from '../../hooks';
import ProductionCardMediaVideoPlayer from './production-card-media-video-player';

const Thumbnail = styled(StylelessButton)`
  width: 100%;

  :disabled {
    opacity: 0.7;
  }
`;

const ProductionCardMediaVideo: React.FC<{
  productionId: number;
  className?: string;
}> = ({
  productionId,
  className,
  children,
}) => {
  const [
    youtubeVideoId,
    isLoadingYoutubeVideo,
    loadYoutubeVideo,
  ] = useProductionYoutubeVideo(productionId);

  return !isLoadingYoutubeVideo && youtubeVideoId ? (
    <ProductionCardMediaVideoPlayer
      youtubeVideoId={youtubeVideoId}
      className={className}
    />
  ) : (
    <Thumbnail
      onClick={loadYoutubeVideo}
      disabled={isLoadingYoutubeVideo}
      className={className}
    >
      {children}
    </Thumbnail>
  );
};

export default styled(ProductionCardMediaVideo)<{
  $height?: number;
}>`
  height: ${(props) => (_.isNil(props.$height)
    ? '100%'
    : `${props.$height}px`
  )};
`;
