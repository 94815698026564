import React from 'react';
import styled from 'styled-components/macro';

const ProductionCardButton = styled.button.attrs((): React.HTMLProps<HTMLButtonElement> => ({
  type: 'button',
  className: 'btn btn-outline-dark',
}))`
  padding: 0.687rem 1.5rem !important;
  border: 1.5px solid #000000 !important;
  border-radius: 20px !important;

  text-align: center !important;
  font-weight: 800 !important;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;

  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 40%;

  &:disabled {
    border-color: rgba(0, 0, 0, 0.4) !important;
    cursor: not-allowed !important;
  }
`;

export default ProductionCardButton;
