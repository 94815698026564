import { useEffect } from 'react';
import { useLocation } from 'react-router';
import ReactGA from 'react-ga';
import Config from '../config';

const usePageView = (customPathname: string) => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.pageview(`${Config.GA.pageViewPrefix}${customPathname}`);
  }, [customPathname]);

  useEffect(() => {
    if (pathname !== customPathname) {
      ReactGA.pageview(`${Config.GA.pageViewPrefix}${pathname}`);
    }
  }, [pathname]);

  return pathname;
};

export default usePageView;
