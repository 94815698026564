import styled from 'styled-components/macro';
import ProductionCardMediaVideo from './production-card-media-video';

const ProductionCardMediaRoundVideo = styled(ProductionCardMediaVideo)`
  & .videoPlayerContainer {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;

export default ProductionCardMediaRoundVideo;
