import { useEffect, useState } from 'react';
import Services from '../services';
import { Nullable } from '../utils/type-utils';
import { ProductionModel } from '../services/production-service';

const useTrendingProductions = () => {
  const [productions, setProductions] = useState<Nullable<ProductionModel[]>>(null);

  useEffect(() => {
    Services.Production.getTrending().then((trendingProductions) => {
      if (!trendingProductions) return;

      setProductions(trendingProductions);
    });
  }, []);

  return productions;
};

export default useTrendingProductions;
