import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactGA from 'react-ga';
import styled from 'styled-components/macro';
import { ProductionsGrid, Spinner, StylelessButton } from '../../components';
import {
  useWatchlistDetailsPage,
  useFurthestIndexViewed,
  useWatchlistDetails,
  useWatchlistIdFromPath,
  usePageView,
} from '../../hooks';
import WatchlistDetailsHeader from './watchlist-details-header';
import watchlistBanner from '../../assets/images/watchlist-banner.png';
import Config from '../../config';
import { Paths } from '../../routes';

const Loading = styled(Spinner)`
  margin: 2rem 0rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 576px) {
    padding: 2rem 3rem;
  }

  @media (min-width: 992px) {
    padding: 2rem 3rem;
    padding-right: 8.5rem;  
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 1rem;

  font-size: 1.75rem;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.4);
`;

const BannerContainer = styled(StylelessButton)`
  display: flex;
  justify-content: center;
  background-color: #6141AC;
`;

const Banner = styled.img`
  object-fit: contain;
  width: 100%;
  max-height: 70px;
`;

const WatchlistDetails: React.FC = () => {
  const watchlistId = useWatchlistIdFromPath();
  const [furthestIndexViewed, onIndexViewed] = useFurthestIndexViewed();
  const [pageNumber, pageLimit] = useWatchlistDetailsPage(furthestIndexViewed);
  const [{ watchlist, productions }, { isLoaded, failed }] = useWatchlistDetails({
    watchlistId,
    pageNumber,
    pageLimit,
  });
  const isMediumScreen = useMediaQuery({ query: '(max-width: 767px)' });
  usePageView(Paths.Watchlist);

  return (
    <Container>
      {failed ? (
        <ErrorMessage>
          Watchlist Not Available
        </ErrorMessage>
      ) : watchlist ? (
        <>
          {isMediumScreen && (
            <BannerContainer
              onClick={() => {
                ReactGA.event({
                  category: Config.GA.watchlistBannerCategory,
                  action: 'Click',
                  label: Config.GA.watchlistBannerLabel,
                });

                window.open(Config.MobileApp.iosLink);
              }}
            >
              <Banner src={watchlistBanner} title="watchlist-banner" />
            </BannerContainer>
          )}
          <WatchlistDetailsHeader watchlist={watchlist} />
          <Content>
            <ProductionsGrid productions={productions} onIndexViewed={onIndexViewed} />
            {!isLoaded && (
              <Loading />
            )}
          </Content>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default WatchlistDetails;
