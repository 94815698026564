import React from 'react';
import Icons from '../icons';

const ReactionCardRatingThumb: React.FC<{
  liked: boolean;
  className?: string;
}> = ({
  liked,
  className,
}) => {
  return liked
    ? (<Icons.ReactionRateUp className={className} />)
    : (<Icons.ReactionRateDown className={className} />);
};

export default ReactionCardRatingThumb;
