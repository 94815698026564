import { useParams } from 'react-router';

const useWatchlistIdFromPath = () => {
  const { id: watchlistIdParam } = useParams();

  const watchlistId = Number(watchlistIdParam);

  return watchlistId;
};

export default useWatchlistIdFromPath;
