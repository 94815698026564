import React from 'react';
import { Icons } from '../../components';
import { Paths } from '../../routes';

interface MenuItem {
  title: string;
  rootPath: string;
  getRedirect: (currentPath: string) => string;
  redirect: boolean;
  renderIcon: (selected: boolean) => JSX.Element;
}

export const menuItems: MenuItem[] = [
  {
    title: 'Home',
    rootPath: Paths.Home,
    getRedirect: () => Paths.Home,
    redirect: true,
    renderIcon: (selected) => (
      selected
        ? <Icons.SelectedHome />
        : <Icons.Home />
    ),
  },
  {
    title: 'Discover',
    rootPath: Paths.Discover,
    getRedirect: (currentPath) => `${currentPath}?showPopup=true`,
    redirect: true,
    renderIcon: (selected) => (
      selected
        ? <Icons.SelectedDiscover />
        : <Icons.Discover />
    ),
  },
  {
    title: 'Watchlist',
    rootPath: Paths.Watchlist,
    getRedirect: (currentPath) => `${currentPath}?showPopup=true`,
    redirect: true,
    renderIcon: (selected) => (
      selected
        ? <Icons.SelectedWatchlist />
        : <Icons.Watchlist />
    ),
  },
  {
    title: 'About',
    rootPath: Paths.About,
    getRedirect: () => Paths.About,
    redirect: true,
    renderIcon: (selected) => (
      selected
        ? <Icons.SelectedAbout />
        : <Icons.About />
    ),
  },
];
