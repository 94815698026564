import styled from 'styled-components/macro';

const StylelessAnchor = styled.a`
  color: unset;
  text-decoration: none;

  :hover, :focus {
    color: unset;
    text-decoration: none;
  }
`;

export default StylelessAnchor;
