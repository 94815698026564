import React from 'react';
import styled from 'styled-components/macro';
import ProductionDiscussionHeader from './production-discussion-header';
import ProductionDiscussionSpoiler from './production-discussion-spoiler';
import ProductionDiscussionProductionSection from './production-discussion-production-section';
import ProductionDiscussionCommentSection from './production-discussion-comment-section';
import { useProductionIdFromPath, useProduction } from '../../hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2rem;

  @media (min-width: 992px) {
    flex-direction: row-reverse;
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (min-width: 1200px) {
    padding-right: 8.5rem;
  }
`;

const ProductionSection = styled(ProductionDiscussionProductionSection)`
  flex-shrink: 0;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const CommentSection = styled(ProductionDiscussionCommentSection)`
  flex-grow: 1;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ProductionDiscussion: React.FC = () => {
  const productionId = useProductionIdFromPath();
  const production = useProduction(productionId);

  return (
    <Container>
      <ProductionDiscussionHeader />
      <ProductionDiscussionSpoiler />
      <Body>
        <ProductionSection production={production} />
        <CommentSection commentsCount={production?.commentsCount} />
      </Body>
    </Container>
  );
};

export default ProductionDiscussion;
