import _ from 'lodash';

const ONE_MILLION = 1000000;
const ONE_THOUSAND = 1000;

const abbreviate = (count: number) => {
  if (count >= ONE_MILLION) return `${_.floor(count / ONE_MILLION, 1)}M`;
  if (count >= ONE_THOUSAND) return `${_.floor(count / ONE_THOUSAND, 1)}K`;

  return `${count}`;
};

const useCountAbbreviation = (count: number) => {
  return [abbreviate(count), count] as const;
};

export default useCountAbbreviation;
