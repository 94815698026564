import { useParams } from 'react-router';

const useProductionIdFromPath = () => {
  const { id: productionIdParam } = useParams();

  const productionId = Number(productionIdParam);

  return productionId;
};

export default useProductionIdFromPath;
