import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { ArrayUtils } from '../../utils';
import { ProductionModel } from '../../services/production-service';
import ProductionsGridEmptySlot from './productions-grid-empty-slot';
import ProductionsGridCard from './productions-grid-card';
import ProductionsGridObservableCard from './productions-grid-observable-card';

const Gutter = styled.div`
  width: 2rem;
  flex-shrink: 0;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 2rem;
`;

const ProductionsGridRow: React.FC<{
  width: number;
  productions: ProductionModel[];
  indexOffset: number;
  onIndexViewed?: (indexViewed: number) => void;
}> = ({
  width,
  productions,
  indexOffset,
  onIndexViewed,
}) => {
  return (
    <Container>
      {ArrayUtils.mapN(width, (index) => {
        const production = ArrayUtils.findByIndex(productions, index);

        return (
          <Fragment
            key={`${production ? production.id : 0}-${index}`}
          >
            {production ? onIndexViewed ? (
              <ProductionsGridObservableCard
                productionId={production.id}
                poster={production.poster}
                type={production.type}
                genres={production.genres}
                title={production.title}
                reactionsCount={production.reactionsCount}
                score={production.score}
                totalRatings={production.totalRatings}
                compact={width === 1}
                onVisibleOnce={() => onIndexViewed(indexOffset + index)}
              />
            ) : (
              <ProductionsGridCard
                productionId={production.id}
                poster={production.poster}
                type={production.type}
                genres={production.genres}
                title={production.title}
                reactionsCount={production.reactionsCount}
                score={production.score}
                totalRatings={production.totalRatings}
                $compact={width === 1}
              />
            ) : (
              <ProductionsGridEmptySlot />
            )}
            {index < width - 1 && (
              <Gutter />
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default ProductionsGridRow;
