import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import { addDays } from 'date-fns';
import { Nullable } from '../utils/type-utils';

const usePageVisited = () => {
  const location = useLocation();
  const [cookies, setCookie] = useCookies([location.pathname]);
  const [pageVisited, setPageVisited] = useState<Nullable<boolean>>(null);

  useEffect(() => {
    if (cookies[location.pathname]) {
      setPageVisited(true);
    } else {
      setPageVisited(false);
      setCookie(location.pathname, 'visited', {
        expires: addDays(Date.now(), 30),
      });
    }
  }, []);

  return pageVisited;
};

export default usePageVisited;
