import { useEffect, useState } from 'react';
import Services from '../services';
import usePagination from './use-pagination';
import usePreparedPages from './use-prepared-pages';
import useSinglePage from './use-single-page';
import usePagesAreLoaded from './use-pages-are-loaded';
import { ProductionModel } from '../services/production-service';
import { WatchlistModel } from '../services/watchlist-service';
import { Nullable } from '../utils/type-utils';

const useWatchlistDetails = ({
  watchlistId,
  pageNumber,
  pageLimit,
}: {
  watchlistId: number;
  pageNumber: number;
  pageLimit: number;
}) => {
  const [watchlist, setWatchlist] = useState<Nullable<WatchlistModel>>(null);
  const [failed, setFailed] = useState<boolean>(false);
  const [productionPages, setProductionPage] = usePagination<ProductionModel>();
  const preparedWatchlistPages = usePreparedPages(productionPages);
  const productions = useSinglePage(preparedWatchlistPages);
  const isLoaded = usePagesAreLoaded({
    pageCount: pageNumber,
    pages: productionPages,
  });

  useEffect(() => {
    Services.Watchlist.getById({
      watchlistId,
      page: pageNumber,
      limit: pageLimit,
    }).then((result) => {
      setProductionPage({
        pageNumber,
        page: result?.productions || [],
      });
      setWatchlist(result);
      setFailed(!result);
    });
  }, [watchlistId, pageNumber, pageLimit]);

  return [{ watchlist, productions }, { isLoaded, failed }] as const;
};

export default useWatchlistDetails;
