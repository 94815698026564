import React, { useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components/macro';
import { Nullable } from '../../utils/type-utils';
import { useIsClamped } from '../../hooks';
import StylelessButton from '../styleless-button';

const Container = styled.div`
  padding-top: 0.5rem;
`;

const Caption = styled.span<{
  $clamp?: boolean;
}>`
  word-break: break-word;
  ${(props) => (props.$clamp ? `
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  ` : '')}
`;

const More = styled(StylelessButton)`
  color: #007bff;
`;

const ReactionCardCaption: React.FC<{
  reactionId: number;
  caption?: Nullable<string>;
}> = ({ caption }) => {
  const [shouldClamp, ref, onHeightChange] = useIsClamped<HTMLDivElement>();
  const [unclamp, setUnclamp] = useState(false);

  return !caption ? null : (
    <ReactResizeDetector
      handleHeight
      onResize={(width, height) => {
        onHeightChange(height);
      }}
    >
      <Container>
        <Caption ref={ref} $clamp={!unclamp}>
          {caption}
        </Caption>
        {shouldClamp && !unclamp && (
          <More
            onClick={() => {
              setUnclamp(true);
            }}
          >
            ...more
          </More>
        )}
      </Container>
    </ReactResizeDetector>
  );
};

export default ReactionCardCaption;
