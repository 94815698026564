import { useState, useEffect } from 'react';
import Services from '../services';
import { ThreadModel } from '../services/comment-service';
import { Nullable } from '../utils/type-utils';

const useCommentsFromProduction = ({
  productionId,
  sort,
}: {
  productionId: number;
  sort?: Nullable<'top' | 'oldest' | 'newest'>;
}) => {
  const [threads, setThreads] = useState<Nullable<ThreadModel[]>>(null);

  useEffect(() => {
    if (productionId && sort) {
      setThreads(null);
      Services.Comment.getByProduction({
        productionId,
        sort,
        limit: 10,
        page: 1,
        depth: 5,
      }).then((foundComments) => {
        setThreads(foundComments);
      });
    }
  }, [productionId, sort]);

  return threads;
};

export default useCommentsFromProduction;
