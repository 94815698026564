import { PublicApi } from './apis';
import { ApiResponse } from './apis/apis';
import { Nilable } from '../utils/type-utils';

type SeasonModel = {
  episodes: number;
  seasonNumber: number;
};

export type ProductionModel = {
  id: number;
  poster: string;
  type: 'Movie' | 'Show';
  genres: string[];
  title: string;
  score: Nilable<number>;
  reactionsCount: number;
  commentsCount?: number;
  seasons?: SeasonModel[];
  totalRatings: Nilable<number>;
};

export type ApiProductionModel = {
  id: number;
  image: string;
  title: string;
  genres: string[];
  overview: string;
  type: ProductionType;
  communityScore: Nilable<number>;
  reactionsCount: number;
  commentsCount?: number;
  seasons?: SeasonModel[];
  totalRatings: Nilable<number>;
};

type ApiVideoModel = {
  key: string;
  type: 'Trailer' | 'Teaser' | 'Clip';
};

enum ProductionType {
  Movie = 1,
  Show = 2,
}

export const mapApiProductionModel = (model: ApiProductionModel): ProductionModel => {
  return {
    id: model.id,
    poster: model.image,
    type: model.type === ProductionType.Movie ? 'Movie' : 'Show',
    genres: model.genres,
    title: model.title,
    score: model.communityScore,
    reactionsCount: model.reactionsCount,
    commentsCount: model.commentsCount,
    seasons: model.seasons,
    totalRatings: model.totalRatings,
  };
};

class ProductionService {
  public static getById = async (productionId: number) => {
    try {
      const response: ApiResponse<ApiProductionModel> = await PublicApi.getProductionById({
        path: {
          id: productionId,
        },
      });

      const fetchedProduction = response?.data || null;
      return fetchedProduction && mapApiProductionModel(fetchedProduction);
    } catch (err) {
      return null;
    }
  };

  public static getTrending = async () => {
    try {
      const response: ApiResponse<ApiProductionModel[]> = await PublicApi.getTrending();

      return response?.data.map((production) => mapApiProductionModel(production)) || [];
    } catch (err) {
      return null;
    }
  };

  public static getYoutubeVideo = async (productionId: number) => {
    try {
      const response: ApiResponse<ApiVideoModel | null> = await PublicApi.getProductionVideo({
        path: {
          id: productionId,
        },
      });

      return response?.data?.key || null;
    } catch (err) {
      return null;
    }
  };
}

export default ProductionService;
