import endpoints from './endpoints';
import { createApi } from '../apis';
import { BaseClient } from '..';
import Config from '../../../config';

const PublicApi = createApi(new BaseClient({
  baseURL: Config.PublicApi.url,
}), endpoints);

export default PublicApi;
