import React from 'react';
import styled from 'styled-components/macro';
import { Icons } from '../../components';
import { useOnVisibleOnce, useFormattedCommentTime } from '../../hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 1rem;
  padding-right: 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 0.25rem;
`;

const UserPortrait = styled.img`
  flex-shrink: 0;

  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const Information = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;

  word-break: break-all;
  font-weight: 800;
`;

const Bullet = styled(Icons.Bullet)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const UploadTime = styled.div`
  display: flex;
  align-items: center;
  color: #767676;
`;

const Content = styled.div`
  padding-left: 2.5rem;
`;

const ReactionDetailsComment: React.FC<{
  reactionId: number;
  userName: string;
  userPortrait: string;
  uploadDate: string;
  content: string;
  onVisibleOnce?: () => void;
  className?: string;
}> = ({
  userName,
  userPortrait,
  uploadDate,
  content,
  onVisibleOnce,
  className,
}) => {
  const [ref] = useOnVisibleOnce(onVisibleOnce);
  const formattedTime = useFormattedCommentTime(uploadDate);

  return (
    <Container
      className={className}
      ref={ref}
    >
      <Header>
        <UserPortrait
          src={userPortrait}
          alt=""
        />
        <Information>
          <UserName>
            {userName}
            <Bullet />
          </UserName>
          <UploadTime>
            {formattedTime}
          </UploadTime>
        </Information>
      </Header>
      <Content>
        {content}
      </Content>
    </Container>
  );
};

export default ReactionDetailsComment;
