import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createGlobalStyle } from 'styled-components/macro';
import * as serviceWorker from './serviceWorker';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.css';
import 'intersection-observer';
import { PopupContextProvider } from './contexts/popup-context';
import Config from './config';

ReactGA.initialize(Config.GA.trackingId);

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
  }

  body {
    background-color: #F3F1FD;
    margin: 0;
    font-family: 'Nunito', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <PopupContextProvider>
      <BrowserRouter basename="/">
        <Routes />
      </BrowserRouter>
      <GlobalStyle />
    </PopupContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
