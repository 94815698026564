import React from 'react';
import styled from 'styled-components/macro';
import { StylelessLink } from '../../components';

const MenuItemBody = styled.div`
`;

const ClientAppMenuItem: React.FC<{
  className?: string;
  path: string;
  redirect: boolean;
  onClick?: () => void;
}> = ({
  className,
  path,
  onClick,
  redirect,
  children,
}) => {
  return redirect ? (
    <StylelessLink to={path} onClick={onClick}>
      <MenuItemBody className={className}>
        {children}
      </MenuItemBody>
    </StylelessLink>
  ) : (
    <MenuItemBody className={className}>
      {children}
    </MenuItemBody>
  );
};

export default ClientAppMenuItem;
