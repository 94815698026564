import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { Icons, StylelessButton } from '../../components';

const Container = styled.div`
  padding: 1rem 1rem;
  background-color: #FFFFFF;

  display: flex;
  align-items: center;

  box-shadow: 0px 4px 8px rgba(64, 48, 141, 0.08);

  @media (min-width: 992px) {
    padding: 1.5rem 3rem;
  }
`;

const BackIcon = styled(StylelessButton)`
  margin-right: 1rem;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2rem;
`;

const ProductionDiscussionHeader: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <BackIcon
        onClick={() => history.goBack()}
      >
        <Icons.ChevronBack />
      </BackIcon>
      <Title>
        Discussion
      </Title>
    </Container>
  );
};

export default ProductionDiscussionHeader;
