import { useEffect, useState } from 'react';
import Services from '../services';
import { Nullable } from '../utils/type-utils';
import { ProductionModel } from '../services/production-service';

const useProduction = (productionId: number) => {
  const [production, setProduction] = useState<Nullable<ProductionModel>>(null);

  useEffect(() => {
    Services.Production.getById(productionId).then((foundProduction) => {
      setProduction(foundProduction);
    });
  }, [productionId]);

  return production;
};

export default useProduction;
