import React from 'react';
import styled from 'styled-components/macro';
import { WatchlistModel } from '../../services/watchlist-service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background-color: #FFF;

  padding: 0.625rem 1.5rem;

  box-shadow: 0px 4px 8px rgba(64, 48, 141, 0.08);

  @media (min-width: 576px) {
    padding: 0.625rem 3rem;
  }

  @media (min-width: 992px) {
    padding-right: 8.5rem;  
  }
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 900;
`;

const Author = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const WatchlistDetailsHeader: React.FC<{
  watchlist: WatchlistModel;
}> = ({ watchlist }) => {
  return (
    <Container>
      <Title>
        {watchlist.name}
      </Title>
      <Author>
        by {watchlist.userDisplayName || 'someone'}
      </Author>
    </Container>
  );
};

export default WatchlistDetailsHeader;
