import React from 'react';
import styled from 'styled-components/macro';
import StylelessButton from '../styleless-button';
import Icons from '../icons';
import { useMobileAppPopup, useCountAbbreviation } from '../../hooks';
import { Nilable } from '../../utils/type-utils';

const Container = styled.div`
  width: 30%;
  padding: 0rem 0.75rem;
`;

const Label = styled.div`
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
`;

const ProductionCardRate: React.FC<{
  totalRatings: Nilable<number>;
}> = ({ totalRatings }) => {
  const { openPopup } = useMobileAppPopup();
  const [abbreviation, count] = useCountAbbreviation(totalRatings || 0);

  return (
    <Container>
      <StylelessButton onClick={openPopup}>
        <Icons.RateUp />
        <Label>
          {count > 1 ? (
            <>{abbreviation} Ratings</>
          ) : count === 1 ? (
            <>{abbreviation} Rating</>
          ) : (
            <>Rate</>
          )}
        </Label>
      </StylelessButton>
    </Container>
  );
};

export default ProductionCardRate;
