import styled from 'styled-components/macro';

const ProductionCardBodyWrapper = styled.div.attrs(() => ({
  className: 'text-center',
}))`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export default ProductionCardBodyWrapper;
