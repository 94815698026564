import React from 'react';
import styled from 'styled-components/macro';
import { StringDropdown, Icons } from '../../components';
import { useProductionDiscussionSort } from '../../hooks';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #EFEFEF;
`;

const Arrow = styled(Icons.ChevronDown16)`
  && {
    right: 0.25rem;
    top: calc(50% - 8px);
  }
`;

const SortSelector = styled(StringDropdown)`
  font-size: 1rem;
  line-height: 1.5rem;
  flex-shrink: 0;
  height: 100%;

  && .MuiInputBase-input {
    margin-left: -0.25rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0.25rem;
    padding-right: 1.5rem;
  }
`;

const CommentCounter = styled.div``;

const ProductionDiscussionCommentHeader: React.FC<{
  commentsCount?: number;
}> = ({ commentsCount }) => {
  const [sort, setSort] = useProductionDiscussionSort();

  return (
    <Container>
      <SortSelector
        Arrow={Arrow}
        onChange={setSort}
        value={sort}
      >
        <option value="top">
          Top Comments
        </option>
        <option value="oldest">
          Oldest
        </option>
        <option value="newest">
          Newest
        </option>
      </SortSelector>
      <CommentCounter>
        {commentsCount ? `${commentsCount} comments` : null}
      </CommentCounter>
    </Container>
  );
};

export default ProductionDiscussionCommentHeader;
