import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

/**
 * TODO: Find a way (maybe CSS in JS?) to solve the need
 * to hardcode CSS width values here
 */
const useGridWidth = (preferredWidth: {
  extraLarge: number;
  large: number;
  default: number;
}) => {
  const [gridWidth, setGridWidth] = useState<number | null>(null);
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1200px)' });
  const isExtraLargeScreen = useMediaQuery({ query: '(min-width: 1550px)' });

  const calculateGridWidth = (windowWidth: number) => {
    const gridItemWidth = isExtraLargeScreen
      ? preferredWidth.extraLarge
      : isLargeScreen
        ? preferredWidth.large
        : preferredWidth.default;
    return Math.trunc(Math.max(windowWidth / gridItemWidth, 1));
  };

  const onWidthChange = (windowWidth: number) => {
    const newGridWidth = calculateGridWidth(windowWidth);
    if (newGridWidth !== gridWidth) setGridWidth(newGridWidth);
  };

  return [gridWidth, onWidthChange] as const;
};

export default useGridWidth;
