import React from 'react';
import { useOnVisibleOnce } from '../../hooks';
import { Nilable } from '../../utils/type-utils';
import ProductionsGridCard from './productions-grid-card';

const ProductionsGridObservableCard: React.FC<{
  productionId: number;
  poster: string;
  type: string;
  genres: string[];
  title: string;
  reactionsCount: number;
  score?: Nilable<number>;
  totalRatings: Nilable<number>;
  compact: boolean;
  onVisibleOnce?: () => void;
  className?: string;
}> = ({
  productionId,
  poster,
  type,
  genres,
  title,
  reactionsCount,
  score,
  totalRatings,
  compact,
  onVisibleOnce,
  className,
}) => {
  const [ref] = useOnVisibleOnce(onVisibleOnce);

  return (
    <ProductionsGridCard
      innerRef={ref}
      productionId={productionId}
      poster={poster}
      type={type}
      genres={genres}
      title={title}
      reactionsCount={reactionsCount}
      score={score}
      totalRatings={totalRatings}
      $compact={compact}
      className={className}
    />
  );
};

export default ProductionsGridObservableCard;
