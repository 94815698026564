import React from 'react';
import styled from 'styled-components/macro';
import { Icons } from '../../components';
import { useOngoingLiveEvent } from '../../hooks';

const Container = styled.div`
  display: flex;
  width: 100%;

  padding: 0.75rem 1rem;
  background-color: #FFF;

  box-shadow: 0px 4px 8px rgba(64, 48, 141, 0.08);
  border-radius: 16px;

  margin-bottom: 2rem;
`;

const NotificationIcon = styled.div`
  margin-right: 0.75rem;
`;

const Message = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-left: -0.25rem;
  margin-right: -0.25rem;
`;

const Title = styled.div`
  font-weight: 800;
  color: #795DE7;

  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;

const Description = styled.div`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;

const WhatsPoppinLiveEventNotification: React.FC = () => {
  const liveEvent = useOngoingLiveEvent();

  return liveEvent && (
    <Container>
      <NotificationIcon>
        <Icons.LiveEventNotification />
      </NotificationIcon>
      <Message>
        <Title>
          {liveEvent.name}
        </Title>
        <Description>
          {liveEvent.description}
        </Description>
      </Message>
    </Container>
  );
};

export default WhatsPoppinLiveEventNotification;
