import { useEffect, useState } from 'react';
import Services from '../services';
import { Nullable } from '../utils/type-utils';
import { LiveEventModel } from '../services/live-event-service';

const useOngoingLiveEvent = () => {
  const [liveEvent, setLiveEvent] = useState<Nullable<LiveEventModel>>(null);

  useEffect(() => {
    Services.LiveEvent.getOngoing().then((ongoingLiveEvent) => {
      setLiveEvent(ongoingLiveEvent);
    });
  }, []);

  return liveEvent;
};

export default useOngoingLiveEvent;
