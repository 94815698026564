import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as Paths from './paths';
import Pages from '../pages';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={Paths.ClientApp}>
        <Pages.ClientApp />
      </Route>
      <Route path={Paths.Default}>
        <Redirect to={Paths.ClientApp} />
      </Route>
    </Switch>
  );
};

export default Routes;
