import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import {
  DetailSection,
  Icons,
  StylelessButton,
  Spinner,
} from '../../components';
import ReactionDetailsCard from './reaction-details-card';
import ReactionDetailsComment from './reaction-details-comment';
import ReactionDetailsCommentBox from './reaction-details-comment-box';
import {
  useReactionIdFromPath,
  useReaction,
  useFurthestIndexViewed,
  useReactionDetailsCommentsPage,
  useCommentsFromReaction,
} from '../../hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const LoadingSpinner = styled(Spinner)`
  margin: 2rem 0rem;
`;

const CommentsSectionHeader = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;

    font-size: 1.5rem;
    font-weight: 800;

    padding-left: 3rem;
    height: 5rem;
    background-color: #FFF;
    box-shadow: 0px 4px 8px rgba(64, 48, 141, 0.08);
  }
`;

const BackIcon = styled(StylelessButton)`
  margin-right: 1rem;
`;

const CommentsSectionBody = styled.div`
  border-top: 1px solid #EFEFEF;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    border-top: none;
    padding-top: 2rem;

    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (min-width: 1200px) {
    padding-right: 8.5rem;
  }
`;

const ShadowedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 16px 16px rgba(64, 48, 141, 0.16);
`;

const CommentBox = styled(ReactionDetailsCommentBox)`

`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
`;

const CommentsList = styled.div`
  width: 100%;
`;

const CommentRow = styled(ReactionDetailsComment)`
  &:first-child {
    padding-top: 1rem;
  }

  padding-bottom: 1rem;
`;

const ReactionDetails: React.FC = () => {
  const history = useHistory();
  const reactionId = useReactionIdFromPath();
  const reaction = useReaction(reactionId);
  const [furthestIndexViewed, onIndexViewed] = useFurthestIndexViewed();
  const [pageNumber, pageLimit] = useReactionDetailsCommentsPage(furthestIndexViewed);
  const [comments, isLoadingComments] = useCommentsFromReaction({
    pageNumber,
    pageLimit,
    reactionId,
  });

  return (
    <Container>
      <DetailSection>
        {reaction ? (
          <ReactionDetailsCard
            reactionId={reaction.id}
            video={reaction.video}
            reactorName={reaction.reactorName}
            reactorPicture={reaction.reactorPicture}
            productionName={reaction.productionName}
            uploadDate={reaction.uploadDate}
            likes={reaction.likes}
            liked={reaction.liked}
            caption={reaction.caption}
            comments={reaction.comments}
            thumbnail={reaction.thumbnail}
            episode={reaction.episode}
            season={reaction.season}
            type={reaction.type}
            format={reaction.format}
            giphyId={reaction.giphyId}
          />
        ) : (
          <LoadingSpinner />
        )}
      </DetailSection>
      <Content>
        <CommentsSectionHeader>
          <BackIcon onClick={() => history.goBack()}>
            <Icons.ChevronBack />
          </BackIcon>
          Comments
        </CommentsSectionHeader>
        <CommentsSectionBody>
          <ShadowedWrapper>
            <CommentBox />
            <CommentsContainer>
              <CommentsList>
                {comments.map((comment, index) => (
                  <CommentRow
                    key={comment.id}
                    reactionId={comment.id}
                    content={comment.content}
                    userName={comment.userName}
                    userPortrait={comment.userPortrait}
                    uploadDate={comment.uploadDate}
                    onVisibleOnce={() => onIndexViewed(index)}
                  />
                ))}
              </CommentsList>
              {isLoadingComments && (
                <LoadingSpinner />
              )}
            </CommentsContainer>
          </ShadowedWrapper>
        </CommentsSectionBody>
      </Content>
    </Container>
  );
};

export default ReactionDetails;
