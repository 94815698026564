import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components/macro';
import { useGridWidth } from '../../hooks';
import { ArrayUtils } from '../../utils';
import { ProductionModel } from '../../services/production-service';
import ProductionsGridRow from './productions-grid-row';

const PREFERRED_WIDTHS = {
  extraLarge: 310,
  large: 310,
  default: 290,
};

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProductionsGrid: React.FC<{
  gridWidth: number;
  productions: ProductionModel[];
  onIndexViewed?: (indexViewed: number) => void;
}> = ({ gridWidth, productions, onIndexViewed }) => {
  return (
    <Grid>
      {ArrayUtils.chunk(productions, gridWidth).map((productionsChunk, index) => {
        return (
          <ProductionsGridRow
            key={`chunk-${index}`}
            width={gridWidth}
            productions={productionsChunk}
            onIndexViewed={onIndexViewed}
            indexOffset={index * gridWidth}
          />
        );
      })}
    </Grid>
  );
};

const ProductionsGridProductionsGrid: React.FC<{
  productions: ProductionModel[] | null;
  onIndexViewed?: (indexViewed: number) => void;
}> = ({ productions, onIndexViewed }) => {
  const [gridWidth, onWidthChange] = useGridWidth(PREFERRED_WIDTHS);

  return (
    <ReactResizeDetector
      handleWidth
      onResize={onWidthChange}
    >
      {productions && gridWidth ? (
        <ProductionsGrid
          gridWidth={gridWidth}
          productions={productions}
          onIndexViewed={onIndexViewed}
        />
      ) : (null)}
    </ReactResizeDetector>
  );
};

export default ProductionsGridProductionsGrid;
