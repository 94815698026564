import React from 'react';
import { generatePath } from 'react-router';
import styled from 'styled-components/macro';
import StylelessLink from '../styleless-link';
import { Paths } from '../../routes';

const Container = styled.div`
  line-height: 2rem;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.25rem;
`;

const ProductionCardTitle: React.FC<{
  productionId: number;
  title: string;
}> = ({
  productionId,
  title,
}) => {
  return (
    <Container>
      <StylelessLink
        to={generatePath(Paths.ProductionDetails, {
          id: productionId,
        })}
      >
        {title}
      </StylelessLink>
    </Container>
  );
};

export default ProductionCardTitle;
