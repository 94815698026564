import React from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components/macro';
import AuthIosPopup from './auth-ios-popup';
import { useIsResetPassword } from '../../hooks';
import { Paths } from '../../routes';

const Container = styled.div`
  padding: 2rem 1rem;
`;

const Auth: React.FC = () => {
  const isResetPassword = useIsResetPassword();

  return (
    <Container>
      {!isResetPassword && (
        <Redirect to={Paths.Home} />
      )}
      <AuthIosPopup
        open
        onClose={() => {}}
      />
    </Container>
  );
};

export default Auth;
