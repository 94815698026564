import React from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { generatePath } from 'react-router';
import styled from 'styled-components/macro';
import StylelessLink from '../styleless-link';
import { Paths } from '../../routes';

const Container = styled(StylelessLink)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Reactor = styled.div`
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1.125rem;

  word-break: break-word;
`;

const UploadDate = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #767676;
`;

const ReactionCardAbout: React.FC<{
  reactionId: number;
  reactorName: string;
  uploadDate: string;
}> = ({
  reactionId,
  reactorName,
  uploadDate,
}) => {
  return (
    <Container
      to={generatePath(Paths.ReactionDetails, {
        id: reactionId,
      })}
    >
      <Reactor>
        {reactorName}
      </Reactor>
      <UploadDate>
        {`${formatDistanceToNowStrict(new Date(uploadDate))} ago`}
      </UploadDate>
    </Container>
  );
};

export default ReactionCardAbout;
