import React from 'react';
import styled from 'styled-components/macro';
import ClientAppMenu from './client-app-menu';
import ClientAppSidebar from './client-app-sidebar';

const Sidebar = styled(ClientAppSidebar)`
`;

const Menu = styled(ClientAppMenu)`
`;

const ClientAppNavigationDesktop: React.FC = () => {
  return (
    <Sidebar
      variant="permanent"
      anchor="left"
    >
      <Menu />
    </Sidebar>
  );
};

export default ClientAppNavigationDesktop;
