// TODO: Fix "as" typings
export const mapObjectValues = <Collection extends object, Output extends unknown>(
  dictionary: Collection,
  mapper: (entry: [keyof Collection, Collection[keyof Collection]]) => Output,
): { [Key in keyof Collection]: Output } => {
  const mappedObject = Object.fromEntries(Object.entries(dictionary).map(
    ([key, value]) => {
      return [key, mapper([key as any, value])];
    },
  ));

  return mappedObject as { [Key in keyof Collection]: Output};
};
