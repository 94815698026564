import React from 'react';
import styled from 'styled-components/macro';
import { ReactionCard } from '../../components';
import { ReactionType } from '../../services/reaction-service';
import { Nilable } from '../../utils/type-utils';

const Container = styled(ReactionCard.FlatContainer)`
  width: 100%;
`;

const Video = styled(ReactionCard.Media)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ReactionDetailsCard: React.FC<{
  reactionId: number;
  video: string;
  reactorName: string;
  reactorPicture: string;
  productionName: string;
  uploadDate: string;
  likes: number;
  liked: boolean;
  comments: number;
  thumbnail: string;
  caption?: string | null;
  episode?: number | null;
  season?: number | null;
  type: ReactionType;
  format: Nilable<string>;
  giphyId: Nilable<string>;
}> = ({
  reactionId,
  video,
  reactorName,
  reactorPicture,
  productionName,
  uploadDate,
  likes,
  liked,
  comments,
  thumbnail,
  caption,
  episode,
  season,
  type,
  format,
  giphyId,
}) => {
  return (
    <Container>
      <ReactionCard.Header>
        <ReactionCard.Portrait
          src={reactorPicture}
          reactionId={reactionId}
        />
        <ReactionCard.About
          reactionId={reactionId}
          reactorName={reactorName}
          uploadDate={uploadDate}
        />
        <ReactionCard.Options />
      </ReactionCard.Header>
      <Video
        video={video}
        thumbnail={thumbnail}
        type={type}
        format={format}
        giphyId={giphyId}
      />
      <ReactionCard.RatingSection>
        <ReactionCard.Production
          name={productionName}
          season={season}
          episode={episode}
        />
        <ReactionCard.RatingThumb liked={liked} />
      </ReactionCard.RatingSection>
      <ReactionCard.Divider />
      <ReactionCard.Caption
        caption={caption}
        reactionId={reactionId}
      />
      <ReactionCard.Footer>
        <ReactionCard.Likes likes={likes} />
        <ReactionCard.Comments
          reactionId={reactionId}
          comments={comments}
        />
      </ReactionCard.Footer>
    </Container>
  );
};

export default ReactionDetailsCard;
