import React from 'react';
import styled from 'styled-components/macro';
import ProductionCardCategorizationGenres from './production-card-categorization-genres';

const Container = styled.div`
  letter-spacing: 0.125rem;
  line-height: 1rem;
  font-size: 0.625rem;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;

  margin-bottom: 0.25rem;
`;

const Bullet = styled.span`
  font-size: 0.625rem;
`;

const ProductionCardCategorization: React.FC<{
  type: string;
  genres: string[];
}> = ({
  type,
  genres,
}) => {
  return (
    <Container>
      <span className="align-middle">{type}</span>
      <Bullet className="align-middle"> &#8226; </Bullet>
      <ProductionCardCategorizationGenres genres={genres} />
    </Container>
  );
};

export default ProductionCardCategorization;
