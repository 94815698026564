import { useEffect, useState } from 'react';
import { ReactionModel } from '../services/reaction-service';
import Services from '../services';
import usePagination from './use-pagination';
import usePreparedPages from './use-prepared-pages';
import useSinglePage from './use-single-page';
import usePagesAreLoaded from './use-pages-are-loaded';
import { Nullable } from '../utils/type-utils';
import useFurthestIndexViewed from './use-furthest-index-viewed';
import useProductionDetailsReactionsPage from './use-production-details-reactions-page';

const useReactionsFromProduction = ({
  productionId,
  seasonNumber,
  episodeNumber,
}: {
  productionId: number;
  seasonNumber: Nullable<number>;
  episodeNumber: Nullable<number>;
}) => {
  const [resetCounter, setResetCounter] = useState(0);
  const [
    furthestIndexViewed,
    onIndexViewed,
    resetFurthestIndexViewed,
  ] = useFurthestIndexViewed();
  const [
    pageNumber,
    pageLimit,
    resetPageNumber,
  ] = useProductionDetailsReactionsPage(furthestIndexViewed);
  const [
    reactionPages,
    setReactionPage,
    resetReactionPages,
  ] = usePagination<ReactionModel>();
  const preparedReactionPages = usePreparedPages(reactionPages);
  const reactions = useSinglePage(preparedReactionPages);
  const pagesAreLoaded = usePagesAreLoaded({
    pageCount: pageNumber,
    pages: reactionPages,
  });
  const isLoading = !pagesAreLoaded;

  useEffect(() => {
    Services.Reaction.getByProduction({
      productionId,
      page: pageNumber,
      limit: pageLimit,
      season: seasonNumber,
      episode: episodeNumber,
      sort: 'newest',
    }).then((results) => {
      setReactionPage({
        pageNumber,
        page: results,
      });
    });
  }, [
    pageNumber,
    resetCounter,
  ]);

  useEffect(() => {
    resetReactionPages();
    resetFurthestIndexViewed();
    resetPageNumber();
    setResetCounter(resetCounter + 1);
  }, [
    productionId,
    seasonNumber,
    episodeNumber,
  ]);

  return [reactions, isLoading, onIndexViewed] as const;
};

export default useReactionsFromProduction;
