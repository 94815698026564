import { useEffect, useState } from 'react';
import { GifResult } from '@giphy/js-fetch-api';
import { Nilable, Nullable } from '../utils/type-utils';
import Services from '../services';

const useGiphyGif = (giphyId: Nilable<string>) => {
  const [gif, setGif] = useState<Nullable<GifResult>>(null);

  useEffect(() => {
    if (!giphyId) {
      setGif(null);
      return;
    }

    Services.Giphy.gif(giphyId).then((result) => {
      setGif(result);
    }).catch(() => {
      setGif(null);
    });
  }, [giphyId]);

  return gif?.data;
};

export default useGiphyGif;
