import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import _ from 'lodash';
import { useGridWidth } from '../../hooks';
import { ArrayUtils } from '../../utils';
import ReactionsGridContainer from './reactions-grid-container';

const PREFERRED_WIDTHS = {
  extraLarge: 320,
  large: 295,
  default: 290,
};

const ReactionsGridResponsiveContainer: React.FC = ({
  children,
}) => {
  const [gridWidth, onWidthChange] = useGridWidth(PREFERRED_WIDTHS);

  const reactions = _.isNil(children)
    ? null
    : ArrayUtils.wrapInArrayIfSingle(children);

  return (
    <ReactResizeDetector
      handleWidth
      onResize={onWidthChange}
    >
      {reactions && gridWidth ? (
        <ReactionsGridContainer
          columns={gridWidth}
        >
          {reactions}
        </ReactionsGridContainer>
      ) : null}
    </ReactResizeDetector>
  );
};

export default ReactionsGridResponsiveContainer;
