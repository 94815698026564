import { PublicApi } from './apis';
import { ApiResponse } from './apis/apis';
import { Nullable } from '../utils/type-utils';
import { ApiProductionModel, mapApiProductionModel, ProductionModel } from './production-service';

export type WatchlistModel = {
  id: number;
  name: string;
  isPrivate: boolean;
  productionsCount: number;
  url: string;
  productions: ProductionModel[];
  userDisplayName: string;
};

type ApiWatchlistModel = {
  id: number;
  name: string;
  isPrivate: boolean;
  productionsCount: number;
  url: string;
  productions: ApiProductionModel[];
  userDisplayName: string;
};

const mapApiModel = (model: ApiWatchlistModel): WatchlistModel => {
  return {
    ...model,
    productions: model.productions.map((production) => mapApiProductionModel(production)),
  };
};

class WatchlistService {
  public static getById = async (params: {
    watchlistId: number;
    page: number;
    limit: number;
  }) => {
    try {
      const response: ApiResponse<Nullable<ApiWatchlistModel>> = await PublicApi.getWatchlistById({
        path: {
          id: params.watchlistId,
        },
        request: {
          limit: params.limit,
          page: params.page,
        },
      });
      const result = response?.data || null;

      const watchlist: Nullable<WatchlistModel> = result ? mapApiModel(result) : null;

      return watchlist;
    } catch (err) {
      return null;
    }
  };
}

export default WatchlistService;
