import { useEffect, useState } from 'react';
import Services from '../services';
import { Nullable } from '../utils/type-utils';
import { ReactionModel } from '../services/reaction-service';

const useReaction = (reactionId: number) => {
  const [reaction, setReaction] = useState<Nullable<ReactionModel>>(null);

  useEffect(() => {
    Services.Reaction.getById(reactionId).then((foundReaction) => {
      setReaction(foundReaction);
    });
  }, [reactionId]);

  return reaction;
};

export default useReaction;
