import React from 'react';
import _ from 'lodash';
import styled from 'styled-components/macro';

const Container = styled.div`
  flex-grow: 1;
`;

const Name = styled.span`
  font-weight: 800;
`;

const ShowDetails = styled.span`
`;

const ReactionCardProduction: React.FC<{
  name: string;
  episode?: number | null;
  season?: number | null;
}> = ({
  name,
  episode,
  season,
}) => {
  return (
    <Container>
      <Name>
        {`${
          name
        }${
          [episode, season].some((showInfo) => !_.isNil(showInfo))
            ? ': '
            : ''
        }`}
      </Name>
      <ShowDetails>
        {!_.isNil(season) && `S${season < 10 ? '0' : ''}${season} `}
        {!_.isNil(episode) && `E${episode < 10 ? '0' : ''}${episode} `}
      </ShowDetails>
    </Container>
  );
};

export default ReactionCardProduction;
