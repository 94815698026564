import React from 'react';
import styled from 'styled-components/macro';
import { useTrendingProductions } from '../../hooks';
import WhatsPoppinLiveEventNotification from './whats-poppin-live-event-notification';
import WhatsPoppinHeader from './whats-poppin-header';
import { ProductionsGrid, Spinner } from '../../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 576px) {
    padding: 2rem 3rem;
  }

  @media (min-width: 992px) {
    padding: 2rem 3rem;
    padding-right: 8.5rem;  
  }
`;

const WhatsPoppin: React.FC = () => {
  const productions = useTrendingProductions();

  return (
    <Container>
      <WhatsPoppinHeader />
      <Body>
        <WhatsPoppinLiveEventNotification />
        <ProductionsGrid productions={productions} />
        {!productions && (
          <Spinner />
        )}
      </Body>
    </Container>
  );
};

export default WhatsPoppin;
