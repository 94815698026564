import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import ClientAppNavigationDesktop from './client-app-navigation-desktop';
import ClientAppNavigationMobile from './client-app-navigation-mobile';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const DesktopNavigation = styled(ClientAppNavigationDesktop)`
`;

const MobileNavigation = styled(ClientAppNavigationMobile)`
`;

const ClientAppNavigation: React.FC = ({ children }) => {
  const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

  return (
    <Container>
      {isLargeScreen ? (
        <DesktopNavigation />
      ) : (
        <MobileNavigation />
      )}
      {children}
    </Container>
  );
};

export default ClientAppNavigation;
