import { useEffect } from 'react';
import { CommentModel } from '../services/comment-service';
import Services from '../services';
import usePagination from './use-pagination';
import usePreparedPages from './use-prepared-pages';
import useSinglePage from './use-single-page';
import usePagesAreLoaded from './use-pages-are-loaded';

const useCommentsFromReaction = ({
  reactionId,
  pageNumber,
  pageLimit,
}: {
  reactionId: number;
  pageNumber: number;
  pageLimit: number;
}) => {
  const [commentPages, setCommentPage] = usePagination<CommentModel>();
  const preparedCommentPages = usePreparedPages(commentPages);
  const comments = useSinglePage(preparedCommentPages);
  const pagesAreLoaded = usePagesAreLoaded({
    pageCount: pageNumber,
    pages: commentPages,
  });
  const isLoading = !pagesAreLoaded;

  useEffect(() => {
    Services.Comment.getByReaction({
      reactionId,
      page: pageNumber,
      limit: pageLimit,
    }).then((results) => {
      setCommentPage({
        pageNumber,
        page: results,
      });
    });
  }, [pageNumber, pageLimit]);

  return [comments, isLoading] as const;
};

export default useCommentsFromReaction;
