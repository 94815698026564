import React from 'react';
import styled from 'styled-components/macro';
import Icons from '../icons';
import StylelessButton from '../styleless-button';
import { useMobileAppPopup } from '../../hooks';

const Container = styled(StylelessButton)`
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.125rem;
`;

const IconWrapper = styled.div`
  margin-right: 0.5rem;
`;

const Likes = styled.div.attrs(() => ({
  className: 'text-wrap text-break',
}))`
`;

const Gutter = styled.div`
  flex-grow: 1;
  min-width: 0.25rem;
  max-width: 1.5rem;
`;

const ReactionCardLikes: React.FC<{
  likes: number;
}> = ({ likes }) => {
  const { openPopup } = useMobileAppPopup();

  return (
    <>
      <Container onClick={openPopup}>
        <IconWrapper>
          <Icons.ReactionLike />
        </IconWrapper>
        <Likes>
          {`${likes} likes`}
        </Likes>
      </Container>
      <Gutter />
    </>
  );
};

export default ReactionCardLikes;
