import React, {
  Fragment,
} from 'react';

const ProductionCardCategorizationGenres: React.FC<{ genres: string[] }> = ({ genres }) => {
  return (
    <>
      {genres.map((genre, index) => {
        return (
          <Fragment
            key={genre}
          >
            <span className="text-nowrap align-middle">
              {`${genre}${index < genres.length - 1 ? '/' : ''}`}
            </span>
            <span className="d-inline-block" />
          </Fragment>
        );
      })}
    </>
  );
};

export default ProductionCardCategorizationGenres;
